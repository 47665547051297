import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

export const Analytics = () => {
    const location = useLocation()

    useEffect(() => {
        const arr = location.pathname.split('/')
        const pathname = arr[arr.length - 1]    
        const addPosition = async() => {
            await fetch("https://navoi-travel.onrender.com/api/page/add", {
              method:"POST",
              headers:{
                'Content-Type':'application/json'
              },
              body:JSON.stringify({
                pathname,
              })
            })
          }
          addPosition()
        console.log(location.pathname)
    }, [location.pathname])
  return null
}
